
export default class Produit {
    constructor(id, libelle, ref, famille, type, gamme) {
      this.id = Number(id);
      this.libelle = libelle;
      this.ref = ref;
      this.famille = famille;
      this.type = type;
      this.gamme = gamme;
      this.sousTotal = 0;
      this.nouveauSousTotal = 0;
      this.articles = []
    }
  }
import Api from '@/services/Api'
import authHeader from './Auth-header';
import store from '../store/index'

function CreateDossier (dossier) {
  console.log(dossier)
  return Api().post('dossier_affaire/create', dossier, { headers: authHeader()})
  //return Promise.resolve( {status : "OK", message_specifique:"", data:{id_fiche:1}})
}

function CreateArticles (dossierArticles) {
  console.log(dossierArticles)
  return Api().post('dossier_affaire/create_articles', dossierArticles, { headers: authHeader()})
  //return new Promise(resolve => setTimeout(resolve, 1000))
}

function CreateVT(VT) {
  console.log(VT)
  return Api().post('Vt/create', VT, { headers: authHeader()})
  //return Promise.resolve({status : "OK", message_specifique:"", data:{id_fiche:33}})
}

function SendDocument(doc) {
  console.log(doc)

  var FormData = require('form-data');
  var data = new FormData();

  data.append('code_app', doc.code_app);
  data.append('numTransaction', doc.numTransaction);
  data.append('id_user_membre', doc.id_user_membre);
  data.append('module', doc.module);
  data.append('bloc_portlet', doc.bloc_portlet);
  data.append('id_bloc_portlet', doc.id_bloc_portlet);
  data.append('id_fiche', doc.id_fiche);
  data.append('titre_doc', '');
  data.append('description_doc', doc.description_doc)
  data.append('VT_Id', doc.VT_Id);
  data.append('VT_JSchedule_api', doc.VT_JSchedule_api);
  data.append('VT_blockId', doc.VT_blockId);
  data.append('VT_blocklIteration', doc.VT_blocklIteration);
  data.append('VT_comment', doc.VT_comment);
  data.append('VT_itemId', doc.VT_itemId);
  data.append('userfile', doc.file);

  return Api().post('/document/upload' , data, 
  {
    headers: {
        'Content-Type': 'multipart/form-data'
    },
    onUploadProgress: function( progressEvent ) {
      store.dispatch('majProgression', { id:doc.id_fiche, uploadPercentage : parseInt( Math.round( ( progressEvent.loaded / progressEvent.total ) * 100 ))})
    }.bind(this)
  })
}


function GetVT(idUser, idVT) {
  console.log(idVT)

  return Api().get('Vt/get/APP_COMMERCIALE/'+ idUser + '/DOSSIER_AFFAIRE/' + idVT , { headers: authHeader()})
}

function UpdateVT(VT) {
  console.log(VT)

  return Api().post('Vt/update', VT , { headers: authHeader()})
}

function GetListe (idUser, status, start, length) {
  console.log(idUser, status)
  return Api().get(`dossier_affaire/list/${idUser}?typeop=${status}&start=${start}&length=${length}` , { headers: authHeader()})
  //return new Promise(resolve => setTimeout(resolve, 1000))
}

function GetChecklist (idDossier) {
  return Api().get(`Checklist/list/${idDossier}?typeop=0` , { headers: authHeader()})
  //return new Promise(resolve => setTimeout(resolve, 1000))
}

export default {
    CreateDossier, CreateArticles, CreateVT, GetVT, SendDocument, UpdateVT,GetListe, GetChecklist
}
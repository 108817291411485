<template>
  <div>
    <b-form-group :label="label" :label-for="id">
      <b-container fluid class="p-4 bg-light" v-show="showPreview">
      <b-row>
        <b-col>
          <b-img thumbnail rounded fluid :src="imagePreview" alt="Image 1"></b-img>
        </b-col>
      </b-row>
    </b-container>
      
      <!-- <button @click="submitFile()">Submit</button> -->
        <b-input-group class="mt-3">
          <b-form-file v-model="file"
          placeholder="Choisir un fichier"
          drop-placeholder="Déplacer le fichier ici ..."
          @input="handleFileUpload()"
          ref="file" 
          browse-text="Choisir"
          :id="id"
        ></b-form-file>
          <b-input-group-append>
            <b-button variant="outline-danger" @click="clearFile()"><b-icon icon="x-circle"></b-icon></b-button>
          </b-input-group-append>
        </b-input-group>
    </b-form-group> 
    <!-- <div class="d-flex w-100 justify-content-between" v-if="doc">
      {{doc.title}} : {{doc.file.name}} 
      <progress v-show="uploadPercentage != 100" max="100" :value.prop="uploadPercentage"></progress>
      <b-icon v-show="uploadPercentage == 100" icon="check-circle-fill" variant="success" font-scale="1"></b-icon>
      <b-icon v-show="doc.status == 'ERROR'" icon="x-circle" variant="danger" font-scale="1"></b-icon>
    </div> -->
  </div>
</template>

<script>
import Document from "../models/document"
  
  export default {
    data(){
      return {
        file: null,
        showPreview: false,
        imagePreview: '',
        uploadPercentage : 0
      }
    },
    props : ['id','blocId', 'questionId', 'label'],
    computed: {
      doc() {
        return this.$store.getters.documents.find(doc => doc.id == this.id)
      }
    },
    watch: {
      doc: {
        handler() {
          if(this.doc)
            this.uploadPercentage = this.doc.uploadPercentage;
        },
        deep:true
      }
    },
    methods: {
      /*
        Submits the file to the server
      */
      submitFile(){
            let formData = new FormData();
            formData.append('file', this.file);

        // axios.post( '/file-progress',
        //     formData,
        //     {
        //         headers: {
        //             'Content-Type': 'multipart/form-data'
        //         },
        //         onUploadProgress: function( progressEvent ) {
        //             this.uploadPercentage = parseInt( Math.round( ( progressEvent.loaded / progressEvent.total ) * 100 );
        //         }.bind(this)
        //     }
        //     ).then(function(){
        //     console.log('SUCCESS!!');
        //     })
        //     .catch(function(){
        //     console.log('FAILURE!!');
        //     });
      },
      clearFile() {
        this.file = null
        this.showPreview = false;
        this.imagePreview = null;
        this.$store.dispatch("supprimerDocument", this.id)
      },
      handleFileUpload(){
        if(this.file == null) 
          return 

        var file = this.file;
        this.$store.dispatch("ajouterDocument", new Document(this.id,this.label, this.blocId, this.questionId, this.file, this.module))

        let reader  = new FileReader();
        reader.addEventListener("load", function () {
          this.showPreview = true;
          this.imagePreview = reader.result;
        }.bind(this), false);

        if( file ){
          if ( /\.(jpe?g|png|gif)$/i.test( file.name ) ) {
            reader.readAsDataURL( file );
          }
        }
      }
    
      // ,submitFiles(){
      //   /*
      //     Initialize the form data
      //   */
      //   let formData = new FormData();

      //   /*
      //     Iteate over any file sent over appending the files
      //     to the form data.
      //   */
      //   for( var i = 0; i < this.files.length; i++ ){
      //     let file = this.files[i];

      //     formData.append('files[' + i + ']', file);
      //   }

      //   /*
      //     Make the request to the POST /multiple-files URL
      //   */
      //   // axios.post( '/multiple-files',
      //   //   formData,
      //   //   {
      //   //     headers: {
      //   //         'Content-Type': 'multipart/form-data'
      //   //     }
      //   //   }
      //   // ).then(function(){
      //   //   console.log('SUCCESS!!');
      //   // })
      //   // .catch(function(){
      //   //   console.log('FAILURE!!');
      //   // });
      // },

      /*
        Handles a change on the file upload
      
      handleFilesUpload(){
        this.files = this.$refs.files.files;
      }*/
    }
  }
</script>
<style scoped>
  div img{
    max-width: 200px;
    max-height: 200px;
  }
</style>
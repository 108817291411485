const state =  {
  dossier: null
};

const getters = {
  selectedDossier : state => { return state.dossier},
  complet : state => { return state.dossier ? Number(state.dossier.statut_attente) == 0 && Number(state.dossier.statut_incomplet) == 0 : false}
}

const mutations = {
  SELECT_DOSSIER(state, dossier) {
    state.dossier = dossier
  }
}

const actions = {
  selectionDossier(context, dossier) {
    context.commit("SELECT_DOSSIER", dossier);
  }
}

export default {
    state, getters, mutations, actions
}
import Vue from 'vue'
import Router from 'vue-router'
import Login from "@/views/Login"
import Dossiers from "@/views/Dossiers"
import DossierDetail from "@/views/DossierDetail"
import Signup from "@/views/Signup"

Vue.use(Router)

const router = new Router({
  routes: [
    {
      path: '/',
      name: 'Login',
      component: Login
    },
    {
      path: '/signup',
      name: 'Signup',
      component: Signup
    },
    {
      path: '/dossiers',
      name: 'dossiers',
      component: Dossiers
    }
    ,
    {
      path: '/dossier/:id',
      name: 'dossier_detail',
      component: DossierDetail
    }
    // {
    //   path: '/ordres/:id_appro_demande',
    //   name: 'ordres',
    //   component: Ordres
    // }
  ]
})

router.beforeEach((to, from, next) => {
  const publicPages = ['/', '/signup'];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem('user');

  if(to.path =="/" && loggedIn != null)
  {
    next("/dossiers")
    
  } else 
  // trying to access a restricted page + not logged in
  // redirect to login page
  if (authRequired && !loggedIn) {
    next('/');
  } else {
    next();
  }
});

export default router
import Vue from 'vue'
import Vuex from 'vuex'
import Catalog from './modules/catalog'
import auth from './modules/auth'
import pieces from './modules/pieces'
import document from './modules/document'
import dossier from './modules/dossier'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    Catalog,
    auth,
    pieces,
    document,
    dossier
  }
})

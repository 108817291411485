<template>
  <ValidationProvider :vid="vid" :rules="rules" :name="name || label" v-slot="{ errors, dirty, validated, valid }">
    <b-form-group :label="label || name" :label-for="name">
      <b-form-input  
        :id="name"
        :type="type"
        :placeholder="placeholder"
        :state="dirty || validated ? valid : null"
        v-model="innerValue"
        trim 
        >
      </b-form-input>
      <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
    </b-form-group>
  </ValidationProvider>
</template>

<script>
import { ValidationProvider, extend } from "vee-validate";

extend('positive', value => {
  console.log(value)
  if (value >= 0) {
    return true;
  }

  return 'The {_field_} field must be a positive number';
});

export default {
  name: "TextInput",
  components: {
    ValidationProvider
  },
  props: {
    vid: {
      type: String,
      default: undefined
    },
    name: {
      type: String,
      default: ""
    },
    label: {
      type: String,
      default: ""
    },
    rules: {
      type: [Object, String],
      default: ""
    },
    placeholder: {
      type: String,
      default: ""
    },
    type: {
      type: String,
      default: "text",
      validator(value) {
        return ["url", "text", "password","tel","search", "number", "email"].includes(value);
      }
    },
    value: {
      type: null,
      default: ""
    }
  },
  data: () => ({
    innerValue: ""
  }),
  computed: {
    hasValue() {
      return !!this.innerValue;
    }
  },
  watch: {
    innerValue(value) {
      this.$emit("input", value);
    },
    value(val) {
      if (val !== this.innerValue) {
        this.innerValue = val;
      }
    }
  },
  created() {
    if (this.value) {
      this.innerValue = this.value;
    }
  }
};
</script>

<style >

</style>
import CatalogService from '../../services/CatalogService'
import Produit from '../../models/produit'
import Article from '../../models/article'

//import Vue from 'vue'

const state =  {
  selectedPacks: [],
  packs :[],
  delta : 0,
  nextId: 1,
};

const getters = {
  packs : state => { return state.packs},
  delta : state => { return state.delta},
  packList(){
    var temp = []
    state.packs.forEach((pack) => {
      console.log(pack.famille)
      var group = temp.filter(x=> { return x.label == pack.famille})
      if(group.length == 0) {
        temp.push({ label: pack.famille, options:[{value:pack.id, text:pack.libelle}]})
      }
      else{
        temp[0].options.push({value:pack.id, text:pack.libelle})
      }
    })
    return temp
  },
  selectedPacks : state => {
    return state.selectedPacks
  }
}

const mutations = {
  GET_PACKS(state, packs) {
    state.packs = packs
  },
  ADD_PACK(state, pack) {
    const index = state.selectedPacks.findIndex(p => p.id == pack.id);
    if(index == -1) {
      state.delta = 0
      state.selectedPacks = state.selectedPacks.concat(pack);
    }
  },
  DELETE_PACK(state, id) {
    state.selectedPacks = state.selectedPacks.filter(p => p.id != id);
  },
  UPDATE_PACK(state, pack) {
    var index = state.selectedPacks.findIndex(p => p.id == pack.id);
    if(index > 0) 
      state.selectedPacks[index] = pack
  },
  UPDATE_ARTICLE(state, article) {
    state.selectedPacks.forEach(pack => {
      pack.articles.forEach(item => {
        if(article.id == item.id) {
          item.prixHT = Math.round(item.prixTTC / (1 + item.tva) * 100) / 100
        }
      });
    });
  }
}

const actions = {
  getPacks( context , id_organisation) {
    CatalogService.GetPacks(id_organisation)
      .then(response => {

        var packs =  []
        
        response.data.list_produits.forEach(produit => {
            var p = new Produit(produit.id_article, produit.designation_ligne_fr,produit.reference_interne, produit.libelle_famille_fr, produit.libelle_type_fr, produit.libelle_gamme_fr)
            
            if(produit.list_articles.length > 0) {
              produit.list_articles.forEach(article => {
                var a = new Article(article.fk_article, article.reference_interne, article.designation_ligne_fr, article.taux_tva, article.p_vente_dev1, article.modifiable)
                p.articles.push(a)
              })
            } else {
                var a = new Article(produit.id_article, produit.reference_interne, produit.designation_ligne_fr, produit.taux_tva, produit.p_vente_dev1, produit.modifiable)
                p.articles.push(a)
            }
            packs.push(p)
        })

        context.commit('GET_PACKS', packs)
      })
      .catch(error => {
        console.log(error)
      })
      // state.packs = [
      //   {
      //   id:0,
      //   libelle : "TESTCRMRRATLANTIC",
      //   famille : "Chauffage",
      //   type: "Pompe à Chaleur Air/Air",
      //   gamme: "AUCUNE",
      //   articles : [
      //       {id:1, ref :'RR7,8KWUE-ATL872926-MAT', prixHT:3700, tva:0.2, modifiable:true, nouveauPrixHT:3700, prixTTC:4440, nouveauPrixTTC: 4440},
      //       {id:2, ref :'RR2KWUI-ATL873929-MAT', prixHT:500, tva:0.2, modifiable:true, nouveauPrixHT:500,prixTTC:600 ,nouveauPrixTTC: 600 },
      //       {id:3, ref :'MORR', prixHT:500, tva:0.1, modifiable:false, nouveauPrixHT:500,prixTTC:550, nouveauPrixTTC: 550,},
      //   ],
      //   sousTotal : 0,
      //   nouveauSousTotal : 0
      // },
      // {
      //   id:1,
      //   libelle : "TESTCRMRO11KWMONOATLANTIC",
      //   famille : "Chauffage",
      //   type: "Pompe à Chaleur Air/Eau",
      //   gamme: "Monophasé",
      //   articles : [
      //       {id:3, ref :'PAC11KWMONO-ATL526300-MAT', prixHT:11000, tva:0.055, modifiable:true, nouveauPrixHT:11000,prixTTC:11605,  nouveauPrixTTC: 11605},
      //       {id:4, ref :'MOMONO', prixHT:3000, tva:0.055, modifiable:false, nouveauPrixHT:3000,prixTTC:3165,  nouveauPrixTTC: 3165},
      //   ],
      //   sousTotal : 0,
      //   nouveauSousTotal: 0
      // }]
  }, 
  addPack(context, pack) {
    context.commit("ADD_PACK", pack);
  },
  deletePack(context, id) {
    context.commit("DELETE_PACK", id);
  },
  updatePack(context, pack) {
    context.commit("UPDATE_PACK", pack);
  },
  updateArticle(context, article) {
    context.commit("UPDATE_ARTICLE", article);
  }
}

export default {
    state, getters, mutations, actions
}
import Api from '@/services/Api'
//import authHeader from './Auth-header';

function GetPacks (id_organisation) {
    return Api().get('catalogue/produits/' + id_organisation)
  }

function SearchArticle (keyword) {
  return Api().get('catalogue/listArticles/' + keyword)
}

export default {
    GetPacks,SearchArticle
}
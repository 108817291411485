export default class Articles {
    constructor(id, ref, designation, tva, prixHT, modifiable) {
      this.id = Number(id);
      this.ref = ref;
      this.designation = designation;
      this.tva = Number(tva) / 100;
      this.prixHT = Number(prixHT);
      this.nouveauPrixHT = Number(prixHT);
      this.modifiable = modifiable || true;
      this.prixTTC = this.prixHT * (1 + this.tva);
      this.nouveauPrixTTC = this.prixHT * (1 + this.tva);
    }
  }
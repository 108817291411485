import AuthService from '../../services/AuthService';

const user = JSON.parse(localStorage.getItem('user'));
const state = user
  ? { status: { loggedIn: true }, user }
  : { status: { loggedIn: false }, user: null };

const getters = {
    user : state => { return state.user},
    idOrganisation : state => { return state.user ? state.user.id_organisation : 0}
}

const actions = 
{
    login({ commit }, user) {
      return AuthService.login(user).then(
        user => {
          commit('loginSuccess', user);
          return Promise.resolve(user);
        },
        error => {
          commit('loginFailure');
          return Promise.reject(error);
        }
      );
    },
    logout({ commit }) {
      AuthService.logout();
      commit('logout');
    },
    create({ commit },data) {
      commit('userCreate');
      return AuthService.create(data)
    }
}


const mutations = {
    loginSuccess(state, user) {
      state.status.loggedIn = true;
      state.user = user;
    },
    loginFailure(state) {
      state.status.loggedIn = false;
      state.user = null;
    },
    logout(state) {
      state.status.loggedIn = false;
      state.user = null;
    },
    userCreate(state) {
      state.status.loggedIn = false;
      state.user = null;
    }
  }


export default {
    state, getters, mutations, actions
}
<template>
<div class="container">
    <b-overlay :show="showOverlay" rounded="sm" variant="light" opacity="0.85" blur="2px">
      <template #overlay>
        <div class="text-center">
          <b-icon icon="stopwatch" font-scale="3" animation="fade"></b-icon>
          <p id="cancel-label">Traitement en cours ...</p>
        </div>
      </template>
    <div class="d-flex flex-row mt-4 justify-content-start">
      <go-back/> <h3>Détail</h3>
    </div>

    <b-skeleton-wrapper :loading="loading">
      <template #loading>
        <b-card class="mb-3" >
          <template v-slot:header>
            <div class="d-flex justify-content-between align-items-center">
              <h6 class="mb-1"><b-skeleton width="60%" variant="dark"></b-skeleton></h6>
              <small><b-skeleton width="55%" variant="dark"></b-skeleton></small>
            </div>

            <p class="mb-1">
                <b-skeleton width="70%"></b-skeleton>
            </p>
          </template>
          <b-list-group flush class="d-flex" >
            <b-list-group-item class="justify-content-between align-items-start">
              <b-row>
                <b-col sm="12"><b-skeleton width="85%"></b-skeleton></b-col>
              </b-row>
              <b-row>   
                <b-col sm="3"><b-skeleton width="85%"></b-skeleton></b-col>
                <b-col sm="3"><b-skeleton width="35%"></b-skeleton></b-col>
              </b-row>
              <b-row>
                <b-col><b-skeleton type="button" width="50%"></b-skeleton></b-col>
              </b-row>
            </b-list-group-item>
          </b-list-group>
        </b-card>
      </template>

      <b-card class="mb-3" header-bg-variant='secondary'>
        <template v-slot:header>
            <div class="text-white">
              <h6 v-if="dossier" class="mb-0 align-items-center">{{dossier.titre_dossier}}</h6>
              <small v-if="dossier" class="mb-0 align-items-center">{{dossier.code_dossier}}</small>
            </div>
          </template>

        <b-list-group flush class="d-flex" v-for="doc in checklist" :key="doc.fk_checklist" >
          <b-list-group-item class="flex-column align-items-start">
            <div class="d-flex w-100 justify-content-between">
              <h6 class="mb-1">{{doc.libelle_checklist}}</h6>
              <span><b-badge :variant="etat(doc.valide)">{{doc.checklist_libelle_valide}}</b-badge></span>
            </div>

            <p class="mb-1" v-if="doc.valide != '1'">
              {{doc.commentaire_rejet}}
              <SingleFileUpload :id="String(doc.fk_checklist)" label="" :blocId="doc.fk_checklist" :questionId=doc.fk_checklist ></SingleFileUpload>
            </p>
            
 
          </b-list-group-item>
        </b-list-group>
        <template v-slot:footer v-if="!complet && documents.length > 0">
           <div class="d-flex w-100 justify-content-end">
            <b-button variant="primary" @click="loading=true; update()" size="sm">Mettre à jour</b-button>
           </div>
         </template> 
      </b-card>
    </b-skeleton-wrapper>
    </b-overlay>
</div>
  
</template>

<script>
import DossierService from '../services/DossierService'
import GoBack from '../components/GoBack.vue'
import SingleFileUpload from '../components/SingleFileUpload.vue'

export default {
  components: {
    GoBack,
    SingleFileUpload
  },
    data() {
      return {
        checklist: [],
        loading:false,
        ordres: [],
        loadingOrdres: false,
        showOverlay : false
      }
    },
    mounted(){
      console.log(this.$route.params)
      this.loadCheckList()
    },
    computed:{
      user() {
        return this.$store.state.auth.user
      },
      documents() {
        return this.$store.getters.documents
      },
      dossier() {
        return this.$store.getters.selectedDossier
      },
      complet() {
        return this.$store.getters.complet
      }
    },
    methods: {
      loadCheckList() {
        if(this.$route.params) {
        this.loading = true
        DossierService.GetChecklist(this.$route.params.id).then(result => {
          this.loading = false
          if(result.data.statut == 'OK') {
            this.checklist = result.data.liste
          }
        })
      }
      },
      etat(id) {
        var str = ''
        switch (id) {
          case '1':
            str = 'success'
            break
          case '2':
            str = 'danger'
            break
          case '3':
            str = 'light'
            break
          case '0':
            str = 'warning'
            break
          default:
            str = ''
            break;
        }
        return str
      },
      async update() {
          this.showOverlay = true
          let docs = []

          this.documents.map( doc => {
            docs.push({
              code_app : "APP_DOSSIER",
              numTransaction : Math.floor(Math.random() * 100000) + 1,
              id_user_membre : this.user.id_user_membre,
              module: "DOSSIER_AFFAIRE",
              titre_doc : "",
              description_doc : doc.title,
              id_fiche : this.$route.params.id,
              VT_blockId : doc.blockId,
              VT_itemId : doc.questionId,
              file: doc.file,
              bloc_portlet : "da_bloc_checklist",
              id_bloc_portlet : doc.id,
            })
          })
          console.log(docs)
          let requests = docs.map(doc => DossierService.SendDocument(doc)
              .then((result) => {
    
                if(result.status =="NOK") {
                  console.log(result.message_specifique)
                }
                else {
                  doc.status = 'SENT'
                  console.log(doc)
                }

              }).catch((error) => {
                
                console.log(error)
                doc.status = 'ERROR'
              })
            )
          
          await Promise.all(requests).then(() => {
            this.showOverlay = false

            this.$bvModal.msgBoxOk('Documents mis à jour', {
                title: 'Confirmation',
                okVariant: 'success',
                headerClass: 'p-2 border-bottom-0 bg-secondary text-white',
                footerClass: 'p-2 border-top-0',
                centered: true
              })

            this.loadCheckList() 
            
          })

      }
    }
}
</script>

<style>

</style>